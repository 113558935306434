@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden;
}

.animate-in {
  animation: animateIn 0.3s ease 0.15s both;
}

@keyframes animateIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
