div.other p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
}

div.other h1 {
  font-size: 24px;
}

div.other h2 {
  font-size: 20px;
}

img.history-img {
  width: 20vw;
  max-width: 400px;
  padding: 20px;
}

div.history-card {
  display: flex;
  padding-bottom: 100px;
}

div.history-card p {
  text-indent: 50px;
}

div.history-card-right p {
  font-size: 16px;
  font-weight: 500;
}
div.history-card-left {
  display: flex;
}

div.history-card-left h2 {
  width: 30vw;
  font-size: 40px;
}

div.history-card-right {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

div.history-card-right h3 {
  width: 50vw;
  font-size: 24px;
}

div.history-card-right p {
  font-size: 16px;
  font-weight: 400;
  padding-right: 20px;
}

@media only screen and (max-width: 600px) {
  div.history-card-left {
    display: none;
  }
}
