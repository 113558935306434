/* input.PhoneInputInput {
  padding: 10px;
  padding-left: 15px;
  font-size: 1.8em;
  font-weight: 600;
  border-radius: 6px;
  background-color: #fff;
}

input.PhoneInputInput:focus {
  outline: 4px solid #ff9d00;
}

input.PhoneInputInput::placeholder {
  color: #c6c6c6;
}

select.PhoneInputCountrySelect {
  pointer-events: none;
} */

input.r::placeholder {
  color: rgb(83, 93, 109);
}

input.r:focus {
  outline: none;
  background-color: #00051d;
}
